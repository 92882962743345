<template>
    <div class="survey">
        <loading v-if="isLoading"/>
        <v-toolbar color="primary" dark extended flat extension-height="7"
                   v-if="!isLoading && !isError && currentSurvey"
                   style="position:fixed; z-index:9999;width:100%;">
            <v-btn icon @click="goBack">
                <v-icon large>close</v-icon>
            </v-btn>
            <v-toolbar-title class="white--text">{{getLocalizedValue(currentSurvey.survey.title)}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!--<v-progress-linear fixed slot="extension" color="white" height="7"
                               :value="Number( (answeredQuestionsCount/questionCount) * 100 )" class="mx-0"/>-->
        </v-toolbar>

        <survey-detail v-if="!isLoading && !isError && currentSurvey" :survey-instance-id="surveyInstanceId" :survey-instance-version="surveyInstanceVersion" style="padding-top:65px;"/>

        <v-alert type="error" icon="error_outline" v-if="isError">
            <span v-html="$t('survey.view.error')"></span>
            <v-btn class="mt-3" light depressed rounded small @click="goBack">{{$t('basic.go_back')}}</v-btn>
        </v-alert>
    </div>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex';
import SurveyDetail from '../../../components/Surveys/SurveyDetail/SurveyDetail';
import {getPendingSurveyByIDFromDB} from '../../../helpers/indexedDB';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'SurveyInstance',
    mixins: [
        GetLocalizedValue
    ],
    props: {
        surveyVersion: {
            type: Number
        },
        mode: {
            default: 'start-survey'
        },
        tempId: {
            type: String
        }
    },
    data: () => ({
        isLoading: false,
        isError: false,
        surveyCurrentVersion: null,
        surveyInstanceVersion: null,
        surveyInstanceId: null,
        surveyInstance: null,
    }),
    computed: {
        ...mapState('surveys', ['currentSurveyVersion', 'surveys', 'currentSurvey']),
        ...mapGetters('surveys', ['questionCount', 'answeredQuestionsCount'])
    },
    components: {
        SurveyDetail
    },
    methods: {
        ...mapMutations('surveys', ['savePendingSurvey', 'setCurrentSurvey', 'setTempSurveyId', 'setSurveyMode', 'setSurveyAnswers', 'updateFilesToQuestion', 'clearSurveyAnswers', 'clearSurveyFiles']),
        goBack() {
            this.savePendingSurvey();
            if (this.mode === 'resume-survey') {
                this.$router.back();
            } else {
                this.$router.push('/home');
            }
        },
        findAndSetSurvey() {
            const currentSurvey = this.surveys.find(survey => survey.survey.id === this.$route.params.surveyId);
            this.setCurrentSurvey(currentSurvey);
        },
        fetchSurvey(version = null) {
            console.log('fetch survey');
            this.isLoadingSurvey = true;
            const survey = {
                id: this.$route.params.surveyId,
                version: version
            };
            this.$store.dispatch('surveys/getSurveyInstanceByVersion', survey).then((data) => {
                this.surveyInstance = JSON.parse(data.survey);
                this.surveyInstanceId = data.instanceId;
                this.surveyInstanceVersion = data.instanceVersion;
                const currentSurvey = {
                    survey: this.surveyInstance
                };
                this.setCurrentSurvey(currentSurvey);
                this.isLoadingSurvey = false;
                this.isError = false;
                this.setTempSurveyId(this.surveyInstanceId);
            }).catch((err) => {
                console.log('error error', err);
                this.isError = true;
            });
        },
        fetchSurveyVersion() {
            this.$store.dispatch('surveys/getSurveyInstance', this.$route.params.surveyId).then((data) => {
                if (data.version) {
                    this.fetchSurvey(data.version);
                } else {
                    console.error("No survey version found", this.$route.params.surveyId);
                    alert("Error occured");
                }
            });
        },
    },
    created() {
        if (this.mode === 'resume-survey') {
            this.clearSurveyFiles();
            this.clearSurveyAnswers();
            this.setTempSurveyId(this.tempId);
            this.setSurveyMode('resume-survey');
            getPendingSurveyByIDFromDB(this.tempId).then((survey) => {
                const tempSurvey = survey[0];
                const currentSurvey = {
                    survey: tempSurvey.currentSurvey
                };
                this.setCurrentSurvey(currentSurvey);
                if (tempSurvey.currentSurveyFiles) {
                    this.setFilesFromIndexedDBToQuestion(tempSurvey.currentSurveyFiles);
                }
                if (tempSurvey.currentSurveyAnswers) {
                    this.setSurveyAnswers(tempSurvey.currentSurveyAnswers);
                }
            });
        }
        if (this.mode === 'start-survey') {
            this.surveyCurrentVersion = this.surveyVersion || localStorage.getItem('CUR_SURVEY_VERSION');
            this.setTempSurveyId();
            this.setSurveyMode('start-survey');
            this.findAndSetSurvey();
        }
        this.fetchSurveyVersion();

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                this.savePendingSurvey();
            }
        });
    },
    beforeDestroy() {
        this.clearSurveyFiles();
        this.clearSurveyAnswers();
    }
};
</script>
